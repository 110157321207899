import {
  EQuizEvents,
  type IQuizCaseEnteredEvent,
  type IQuizCaseUnlockedEvent,
  type IQuizGameAnswerEvent,
  type IQuizGameEndedEvent,
} from '../types/quiz.types';

export class QuizEvents {
  static caseEntered(event: IQuizCaseEnteredEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EQuizEvents.CASE_ENTERED,
      eventProperties: event,
    });
  }

  static caseUnlocked(event: IQuizCaseUnlockedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EQuizEvents.CASE_UNLOCKED,
      eventProperties: event,
    });
  }

  static gameAnswer(event: IQuizGameAnswerEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EQuizEvents.GAME_ANSWER,
      eventProperties: event,
    });
  }

  static gameEnded(event: IQuizGameEndedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EQuizEvents.GAME_ENDED,
      eventProperties: event,
    });
  }

  static gameStarted() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EQuizEvents.GAME_STARTED,
    });
  }

  static tabRedirect() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EQuizEvents.TAB_REDIRECT,
    });
  }
}
