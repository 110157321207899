export enum EQuizEvents {
  CASE_ENTERED = 'Quiz - Quiz Case Entered',
  CASE_UNLOCKED = 'Quiz - Quiz Case Unlocked',
  GAME_ANSWER = 'Quiz - Quiz Game Answer',
  GAME_ENDED = 'Quiz - Quiz Game Ended',
  GAME_STARTED = 'Quiz - Quiz Game Started',
  TAB_REDIRECT = 'Quiz - Quiz Tab Redirect',
}

export interface IQuizCaseEnteredEvent {
  'case name': string;
}

export interface IQuizCaseUnlockedEvent {
  'case name': string;
}

export interface IQuizGameAnswerEvent {
  'is true': string;
}

export interface IQuizGameEndedEvent {
  'is won': string;
}
